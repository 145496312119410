import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Game loop animation with React Hooks",
  "description": "Change state at 60fps and voila, animation 🤯",
  "date": "2020-06-09T09:13:00.000Z",
  "published": "2020-06-09T09:13:00.000Z",
  "image": "../../images/articles/screenshot-1591716513625.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When React came on the scene it made a big splash with its DOM diffing engine. You could build UI just like you'd build a game 😍`}</p>
    <p>{`No more fiddly work with figuring out the difference between one frame and another. Change state, let React handle the rest.`}</p>
    <p>{`It was amazing.`}</p>
    <p>{`I built a simple Space Invaders clone and gave my first big tech talk in San Francisco about it.`}</p>
    <lite-youtube {...{
      "videoid": "UP1nCXG2t4M",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`Nowadays that's nothing special. Every framework can do it. Oh you can change state, re-render, and it all just works?`}</p>
    <p>{`Boooooring.`}</p>
    <p>{`But a lot of people don't realize that you can use this trick for animation. Change state 60 times per second and magic happens.`}</p>
    <p>{`That's how video games work.`}</p>
    <p>{`You can think of a video game as a mutating state tree. Each action adds some change to the tree. The game then engine re-renders your frame as a pure representation of that state.`}</p>
    <p>{`Very much like React.`}</p>
    <p>{`Your components render from state and props. Change those and the components re-render. Change them fast enough and they animate.`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media1.giphy.com/media/3o84U6421OOWegpQhq/giphy-loop.mp4?cid=4ac046a28keup0xbjwig8kxponmrlm3t9dety8n5w0teul21&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <h2 {...{
      "id": "animating-with-hooks-and-state"
    }}>{`Animating with Hooks and state`}</h2>
    <p>{`Here's how this game loop approach to animation works in action. An animated spiral.`}</p>
    <p>{`Silly example I know. We're focusing on the technique 😛`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/game-loop-animation-hooks-kxxml?file=/src/Spiral.js",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/game-loop-animation-hooks-kxxml?file=/src/Spiral.js"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/twA82JK.gif",
          "alt": null
        }}></img></a></p>
    <p>{`Frame mismatch between gif and UI makes it look jittery. Silky smooth if you click through I promise.`}</p>
    <h3 {...{
      "id": "heres-how-it-works"
    }}>{`Here's how it works`}</h3>
    <p>{`We have a `}<inlineCode parentName="p">{`<Spiral>`}</inlineCode>{` component that's rendered inside an SVG element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<svg width="400" height="400">
  <Spiral cx={200} cy={150} maxR={75} />
</svg>
`}</code></pre>
    <p>{`Takes a center-x, center-y, and max radius as props.`}</p>
    <p>{`The component uses those to render a `}<inlineCode parentName="p">{`<circle>`}</inlineCode>{` element with some colors.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
  <g transform={\`translate(\${cx}, \${cy})\`}>
    <circle
      cx={x}
      cy={y}
      r={10}
      fill={color}
      stroke={borderColor}
      strokeWidth={2}
    />
  </g>
)
`}</code></pre>
    <p>{`The grouping `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` elements moves our coordinate system to `}<inlineCode parentName="p">{`cx, cy`}</inlineCode>{` and the `}<inlineCode parentName="p">{`<circle>`}</inlineCode>{` renders a circle. Attributes define its coordinates and color.`}</p>
    <p>{`We get those attributes with high school trigonometry.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const x = Math.cos((angle * Math.PI) / 180) * radius.r
const y = Math.sin((angle * Math.PI) / 180) * radius.r
const color = d3.interpolateRdBu(angle / 360)
const borderColor = d3.interpolateRdBu((360 - angle) / 360)
`}</code></pre>
    <p>{`X is the cosine of `}<inlineCode parentName="p">{`angle`}</inlineCode>{` in radians multiplied with current radius. Y is the sine.`}</p>
    <p>{`D3 helps us get a new color for every angle. Makes it flashy.`}</p>
    <h3 {...{
      "id": "the-game-loop"
    }}>{`The game loop`}</h3>
    <p>{`All of that won't make an animation. It just makes a circle.`}</p>
    <p>{`What you need next is the game loop. It comes in 3 parts.`}</p>
    <ol>
      <li parentName="ol">{`The state`}</li>
      <li parentName="ol">{`The animation tick method`}</li>
      <li parentName="ol">{`The timer`}</li>
    </ol>
    <p><strong parentName="p">{`The state`}</strong>{` defines the 2 parameters we'll change with each tick of the animation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// using R and V for radius helps us invert the spiral at 0
const [radius, setRadius] = useState({ r: maxR, v: -(maxR / 360) })
const [angle, setAngle] = useState(0)
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`radius`}</inlineCode>{` state has two parts. The current radius, `}<inlineCode parentName="p">{`r`}</inlineCode>{`, and the current velocity, `}<inlineCode parentName="p">{`v`}</inlineCode>{`. This helps us change between making it bigger or smaller when we hit an edge.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`angle`}</inlineCode>{` state holds the current angle. Feed it into sine and cosine to get coordinates.`}</p>
    <p><strong parentName="p">{`The animation tick method`}</strong>{` runs about 60 times per second and manipulates this state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function tickAnimation() {
  // run angle in a circle
  // adjust +2 to speed up and fit entire animation in a certain time
  setAngle((angle) => (angle + 2) % 360)
  setRadius((radius) => {
    let { r, v } = radius
    r += v

    // invert movement direction at 0 and maxR
    if (r < 0) {
      v = +(maxR / 360)
    } else if (r > maxR) {
      v = -(maxR / 360)
    }

    return { r, v }
  })
}
`}</code></pre>
    <p>{`Change angle with a modus of 360 to run in a circle, change radius inward or outward depending on velocity. Invert velocity when you reach different limits.`}</p>
    <p><strong parentName="p">{`The timer`}</strong>{` triggers our `}<inlineCode parentName="p">{`tickAnimation`}</inlineCode>{` function to drive the animation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// start game loop timer on mount
useEffect(() => {
  const t = d3.timer(tickAnimation)
  return () => t.stop()
}, [])
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` hook runs on mount, starts a `}<inlineCode parentName="p">{`d3.timer`}</inlineCode>{` which calls our method about 60 times per second, and cleans up with a `}<inlineCode parentName="p">{`stop()`}</inlineCode>{`. Ensures we don't run the animation after component unmounts.`}</p>
    <p>{`You can think of D3 timer as a more reliable `}<inlineCode parentName="p">{`setInterval`}</inlineCode>{` ✌️`}</p>
    <p>{`And voila, an animated spiral that looks jittery on a gif and wonderful in real life.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/game-loop-animation-hooks-kxxml?file=/src/Spiral.js"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/twA82JK.gif",
          "alt": null
        }}></img></a></p>
    <h2 {...{
      "id": "how-this-approach-scales"
    }}>{`How this approach scales`}</h2>
    <p>{`The game loop approach is fantastic because it gives you lots of power. You can do whatever you want, as intricate as you want.`}</p>
    <p>{`The game loop approach sucks because it gives you lots of power and lets you do whatever you want.`}</p>
    <p>{`😅`}</p>
    <p>{`Oftentimes you don't want this much power. You want something simpler and easy to use. Less power, more magic.`}</p>
    <p>{`That's where transitions come in. Let D3 handle the details.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media2.giphy.com/media/d3mlE7uhX8KFgEmY/giphy.gif",
        "alt": null
      }}></img></p>
    <p>{`Wanna learn more about animating with modern React? Check out `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React for Dataviz`}</a>{`. Comes with a special live workshop just this week`}</p>
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      